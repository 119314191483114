<template>
    <div class="questionsBox">
        <b-container>
            <h2 data-v-36d51856="" class="Orangetitle">Frequently asked questions</h2>
            <div class="accordion" role="tablist">
                <b-card
                no-body
                class="mb-1"
                v-for="(faq, index) in page"
                :key="index"
                >
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                    block
                    v-b-toggle="'faq' + index"
                    :id="index"
                    class="faqQ"
                    >
                    {{ faq.question }}
                        <span class="arrow" >
                        <span class="plusIcon">
                            <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                        </span>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse
                    :id="'faq' + index"
                    :accordion="'faq' + index"
                    role="tabpanel"
                >
                    <b-card-body>
                    <b-card-text v-html="faq.answer"></b-card-text>
                    <!-- <b-card-text>{{ text }}</b-card-text> -->
                    </b-card-body>
                </b-collapse>
                </b-card>
            </div>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Questions and Answers',
  components: {

  },
  data: function() {
    return {
      page: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
           process.env.VUE_APP_API_URL + 'flightpark' + "/wp-json/wp/v2/pages/20" + '?randomadd=' +
          new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.page = response.data.acf.faq_list,
            console.log("this.page"),
            console.log(this.page)
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
    p.card-text {
        text-align: left;
    }
    .questionsBox {
        margin-bottom: 100px;
    }
    button.btn.faqQ.btn-secondary.btn-block.collapsed{
        color: #11182F;
        font-size: 25px;
        background: #fff;
    }
    button.btn.faqQ.btn-secondary.btn-block.not-collapsed{
        color: #11182F;
        font-size: 25px;
        background: #fff;
    }
    .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
        background-color: #fff;
        border-color: #fff;
    }
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid #000;
        border-radius: 0rem;
        margin-bottom: 30px !important;
    }
    .accordion > .card {
        overflow: hidden;
        border-bottom: 1px solid #000 !important;
    }
    .card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.0);
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        border: 0px solid;
    }
    .btn-secondary:focus, .btn-secondary.focus {
        color: #1f1545;
        background-color: #eff8fc;
        border-color: #545b62;
        box-shadow: 0 0 0 0rem rgb(255, 255, 255);
    }
    .Orangetitle {
        color: #5D8EF0;
        font-size: 40px;
        font-family: "korolev", sans-serif;
        font-weight: 700;
        font-style: italic;
        margin-bottom: 60px;
    }
    p {
        color: #11182F;
        font-size: 22px;
    }
    button.btn.faqQ.btn-secondary.btn-block.not-collapsed {
        color: #11182F;
        font-size: 25px;
    }
    button.btn.faqQ.btn-secondary.btn-block.collapsed {
        color: #11182F;
        font-size: 25px;
    }
    .faqQ {
        background-color: #fff;
        border: 0;
        text-align: left;
        color: #1f1545;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: left;
        font-weight: 600;
        padding-top: 25px;
        padding-bottom: 25px;
        font-size: 13pt;
    }
    .faqQ:hover {
        background-color: #fff;
        border: 0;
        text-align: left;
        color: #1f1545;
        border: none!important;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: left;
        font-weight: 600;
        padding-top: 25px;
        padding-bottom: 25px;
        font-size: 13pt;
    }
    .collapsed:after {
        content: "";
        background: url('https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/unnamed-file-1.png');
        float: right;
        padding-right: 15px;
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 20px;
    }
    .not-collapsed:after {
        content: "";
        background: url('https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/unnamed-file.png');
        float: right;
        padding-right: 15px;
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 20px;
    }
    @media only screen and (max-width: 768px) {
        p {
            color: #11182F;
            font-size: 16px;
        }
        button.btn.faqQ.btn-secondary.btn-block.collapsed{
            color: #11182F;
            font-size: 18px;
            background: #fff;
            width: 90%;
        }
        button.btn.faqQ.btn-secondary.btn-block.not-collapsed{
            color: #11182F;
            font-size: 18px;
            background: #fff;
            width: 90%;
        }
        .collapsed:after {
            content: "";
            background: url('https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/unnamed-file-1.png');
            float: right;
            padding-right: 15px;
            display: inline-block;
            vertical-align: middle;
            width: 50px;
            height: 50px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            top: 15px;
        }
        .not-collapsed:after {
            content: "";
            background: url('https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/unnamed-file.png');
            float: right;
            padding-right: 15px;
            display: inline-block;
            vertical-align: middle;
            width: 50px;
            height: 50px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            top: 15px;
        }
    }
</style>
