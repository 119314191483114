<template>
  <div class="EnquireyBanner">
    <div class="homepage container">
        <b-row>
            <b-col lg="6" sm="12">
                    <h2 class="Whitetitle">Fly Iconic Locations</h2>
                    <p class="WhiteStandard-text aboutTrack-width">Experience takeoffs, landings, and flights over breathtaking landscapes and famous airports worldwide.</p>
            </b-col>
            <b-col lg="6" sm="12">
                <b-link :to="'/contact'">
                    <b-button class="EnquiryBtn" variant="">Enquire Today</b-button>
                </b-link>
            </b-col>
        </b-row>      
        <!-- <b-row class="above1200">
            <b-col lg="6" sm="12">
                <div class="item-1">
                    <h2 class="Whitetitle">Private and discrete booking available</h2>
                    <p class="WhiteStandard-text aboutTrack-width">Get in touch today to book our whole studio to allow total privacy during your sessions.</p>
                </div>
                <div class="item-2">
                    <h2 class="Whitetitle">Book Now by downloading the SimPark App</h2>
                    <p class="WhiteStandard-text aboutTrack-width">Easy to use App online booking system</p>
                </div> 
                <div class="item-3">
                    <h2 class="Whitetitle">Play 18 holes of some of the worlds most famous courses.</h2>
                    <p class="WhiteStandard-text aboutTrack-width">Analyse your game with the most accurate ball flight data</p>
                </div> 
            </b-col>
            <b-col lg="6" sm="12">
                <b-link :to="'/contact'">
                    <b-button class="EnquiryBtn" variant="">Enquire Today</b-button>
                </b-link>
            </b-col>
        </b-row> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Enquirey Banner',
  components: {

  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + 'golfpark' + "ocs-studios/wp-json/wp/v2/pages/6"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
.TextBlock {
    position: relative;
    width: 100%;
    bottom: 7em;
    left: -20em;
}
.item-1, 
.item-2, 
.item-3 {
	position: absolute;
    display: block;
    top: 2em;
    width: 100%;
    font-size: 2em;
	animation-duration: 15s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-1{
	animation-name: anim-1;
}

.item-2{
	animation-name: anim-2;
}

.item-3{
	animation-name: anim-3;
}

@keyframes anim-1 {
	0%, 8.3% { left: -100%; opacity: 0; }
  8.3%,25% { left: 25%; opacity: 1; }
  33.33%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-2 {
	0%, 33.33% { left: -100%; opacity: 0; }
  41.63%, 58.29% { left: 25%; opacity: 1; }
  66.66%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-3 {
	0%, 66.66% { left: -100%; opacity: 0; }
  74.96%, 91.62% { left: 25%; opacity: 1; }
  100% { left: 110%; opacity: 0; }
}
.Whitetitle {
    color: #fff;
    font-family: "korolev", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 40px;
    text-align: left;
}
.WhiteStandard-text {
    font-size: 24px;
    color: #fff;
    text-align: left;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.EnquireyBanner {
    background-color: #5D8EF0;
    padding: 50px;
    background-image: url(https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Screenshot-2024-02-20-at-14.20.56.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
button.EnquiryBtn {
    background-color: #1A1A1A;
    border-color: #1A1A1A !important;
    border-radius: 50px;
    width: 163px;
    height: 31px;
    line-height: 1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 262px;
    height: 54px;
    margin-top: 27px;
    float: right;
    margin-right: 30px;
    font-family: "noto-sans", sans-serif;
    margin-bottom: 37px;
}
button.EnquiryBtn:hover {
    background-color: #707070;
    border-color: #707070!important;
    color: #fff;
}



/* Mobile Changes */
@media only screen and (min-width: 1201px) {
    .below1200 {
        display: none;
    }
}
@media only screen and (max-width: 1200px) {
    .above1200{ 
        display: none;
    }
}
@media only screen and (max-width: 768px) {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    button.EnquiryBtn {
        background-color: #1A1A1A;
        border-color: #1A1A1A !important;
        border-radius: 50px;
        width: 163px;
        height: 31px;
        line-height: 1;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        height: 54px;
        margin-top: 40px;
        float: none;
        margin-right: 0px;
        font-family: "noto-sans", sans-serif;
    }
    .Whitetitle {
        color: #fff;
        font-family: "korolev", sans-serif;
        font-weight: 700;
        font-style: italic;
        font-size: 36px;
        text-align: left;
    }
    .WhiteStandard-text {
        font-size: 20px;
        color: #fff;
        text-align: left;
        font-family: "noto-sans", sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    .EnquireyBanner {
        background-color: #5D8EF0;
        padding: 50px;
        padding-top: 70px;
        padding-bottom: 70px;
        background-image: url(https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Screenshot-2024-02-20-at-14.20.56.png);
        background-repeat: no-repeat;
        background-size: revert;
        background-position: center;
    }
}

</style>
